import data from "../data/data.js"   
export default function Services() {
    return ( 
        <div className="m-4 p-4 lg:grid lg:grid-cols-3 lg:gap-10 lg:m-5">     
            {data.map((service, i) => (     
              <div className="text-center shadow-md shadow-pink p-10 rounded-xl my-6 font-quicksand text-dk-blue bg-white">
                <img key={i} className="lg:rounded-md lg:overflow-hidden items-center" width="350px" height="150px" src={service.img} alt={service.title} />
                <h2 className="text-xl lg:mb-4 lg:font-bold">{service.title}</h2>
                <p className="text-sm text-justify lg:pb-3 ">{service.description}</p>
                <a href={service.link} className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>{service.button}</a>
              </div>         
            ))}        
        </div>   
    )
}

    