import IG from "../images/IG.png";
// import FB from "../images/facebook.png"
import LinkedIn from "../images/linkedIn.png"

export default function Footer() {
    return (
        <footer className="lg:flex lg:justify-center lg:gap-20 lg:text-xs items-center font-quicksand p-4 bg-neutral text-neutral-content">
             
                <p>Copyright © 2023 Live A Better Life 24-7</p>
                <p>Tele: 856-318-9896</p>
                <p>Email: liveabetterlife247@gmail.com</p> 
                <div className="flex">
                    <a href="https://www.instagram.com/labl247/" target="blank"><img height="75" width='75' className="fill-current" src={IG} alt=""/></a>
                    <a href="https://www.linkedin.com/in/elizabethbright721/" target="blank"><img height="75" width='75' className="fill-current" src={LinkedIn} alt=""/></a>
                </div>
        </footer>
    )
}