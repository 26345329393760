
export default function CoachingServices() {
  return  ( 
    <div className="bg-dk-blue m-5">
      <div className='bg-white '>
         <h1 className="font-greatvibes text-3xl text-dk-blue text-center mb-5">The Coaching Relationship</h1>
        <div className='container mx-auto font-quicksand'>
          <h1 className='text-xl'>Coaching</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-md'>Our coaching involves the ability to understand you: to think how you think, and understand your perspective. From this benchmark, I will place another perspective on top of yours – stretching and growing you to gently see a more empowering way. As a certified John Maxwell coach, I can see what you are going through now and what is up ahead. Coaching is foreseeing, paving the way, coming alongside you while helping you achieve goals and overcome limiting paradigms.  My goal is to help you re-imagine your life.  With experience in Life Coaching and a vast history of personal and professional success, my individualized positivity approach will help you attain your life goals.  </p>
            </div> 
        </div> 
        <div className='container mx-auto font-quicksand'>
          <h1 className='text-xl'>Support for the Journey</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-md'>My goal is to help you re-imagine your life.  With experience in Life Coaching and a vast history of personal and professional success, my individualized positivity approach will help you attain your life goals.    </p>
            </div> 
        </div> 
        <div className='container mx-auto font-quicksand'>
          <h1 className='text-xl'>Professionalism and Experience</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-md'>As a professionally trained and experienced life coach, I have the tools and understanding to help you to create the life you imagine and get a renewed sense of self.      </p>
            </div> 
        </div> 
        <div className='container mx-auto font-quicksand'>
          <h1 className='text-xl '>The Coaching Relationship</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-md'>In the coaching relationship, I am here to help you clarify your goals, values, and life purpose by creating greater awareness, increase responsibility, and developing accountability.    </p>
            </div> 
            </div>
                
        </div> 
        
    </div>
)
}