import AboutCoaches from '../Components/Coaching/AboutCoaches'
import CoachingServices from '../Components/Coaching/CoachingServices.js'
import CoachingPackages from '../Components/Coaching/CoachingPackages'

export default function Coaching() {
    return  ( 
      <div >
        <CoachingServices />
        <div className='bg-white p-5'>
        <CoachingPackages />
        </div>


        </div>
    ) 
}        