
const services = [
    {
        img: require("../images/The15InvaluableLawsOfGrowth.jpg"),
        title: "Speaking & Workshops",
        description: "My speaking engagements are crafted to ignite a spark within individuals, encouraging them to push past their limits, embrace challenges, and achieve their fullest potential.",
        button: "Click Here",
        link: "Speaking",
    },
    {
        img: require("../images/PutYourDreamToTheTestMastermind_Ad2.png"),
        title: "Training",
        description: "I believe that every individual has the capacity to achieve greatness, and my goal is to unlock that potential through powerful and impactful presentations. My approach is not just to motivate, but to equip attendees with the tools and strategies they need to implement lasting change in their personal and professional lives",
        button: "Click Here",
        link: "Trainings",
    },
    {
        img: require("../images/life-coach.png"),
        title: "Personal Growth Coaching",
        description: "Design a life you love by pursuing your true purpose.  Establish your own personal growth plan for the change you want to see through a Certified Life Coach partnership.",
        button: "Click Here",
        link: "Coaching",
    },
    {
        img: require("../images/web_serv.png"),
        title: "Branding Services",
        description: "Build your brand with a website, logo, business cards, postcards, flyers, and signage, along with social media and email marketing.",
        button: "Click Here",
        link: "#",
    },
    // {
    //     img: "../images/event-plan.png",
    //     title: "Project Mgmt/Event Planning",
    //     description: "As an project manager or event planner, I will coordinate all aspects of your project/event including preparing timelines and to-do lists, setting up vendor arrangements, ensuring that all aspects of your project come together from beginning to end within your desired budget. ",
    //     button: "More Info Coming Soon",
    //     link: "#",
    // },

    {
        img: require("../images/DISC-Diagram.png"),
        title: "Maxwell DISC Method",
        description: "The best thing you can do to grow yourself and others is to understand your personality and what naturally drives you.  When you recognize your strengths and weaknesses, you are able to give your best as you work with people around you.",
        button: "Click Here",
        link: "#",
    },
    {
        img: require("../images/Wellness.png"),
        title: "Health & Wellness",
        description: "As a Health and Wellness Distributor, we provide products that promote health and wellbeing, such as energy, weight loss or weight management solutions. We believe we have a product to help you.  ",
        button: "Shop Here",
        link: "https://partner.co/s/OWJkZjRhYzdl",
    },
];
export default services;