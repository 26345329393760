export default function OurJourney() {
    return (
        <div className="lg:flex p-10 gap-5 m-4">
            <div className="rounded-full overflow-hidden">
                 <img src={require("../../images/CoachAl&E.png")} className="feature-photo-three" alt="Coaches" width="1000px" height="500px" /> 
            </div>
             <div>
                 <h2 className="text-3xl font-greatvibes ">Our Journey!</h2>
                 <p className="font-quicksand m-0 lg:text-xl">We launched Live A Better Life 24•7 based on our passions for personal and professional development, as well as, our passion as health and wellness enthusiasts and branding specialist. The vision is to provide tools and resources for individuals and businesses to help you achieve your personal and professional goals to Live a Better Life 24•7.</p>
             </div>
        </div>
        
    )
}