

export default function Speaking() {
    return  ( 
      <div className="m-5">
           <h1 className="font-greatvibes text-3xl text-dk-blue text-center mb-5">Speaking, Workshops, and Seminars</h1>
          <div className='container mx-auto font-quicksand text-xl'>
              <div className="m-4 p-4 flex  lg:gap-10 lg:m-5">
                <div>
                <p>In addition to my certificaton as a Maxwell Leadership Speaker, I offer customized presentations to fit your corporate or ministry needs and budget such as:</p>
                <ul className="list-disc m-10">
                        <li>Keynote speaker for executive or ministry retreats</li>
                        <li>Half day and full day workshop/seminar</li>
                        <li>In-house corporate training</li>
                        <li>Lunch and Learn</li>
                </ul>
                <p>I will at your request, incorporate your organization’s meeting theme, mission/vision statement or build on your existing training schedule to best fit the needs of your group.</p>
                <br/>
                <a href="/Contact" className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Contact Us</a>
           
                </div>
                </div> 
          </div> 
      </div>
  )
  }