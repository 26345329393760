import { useRef } from "react"
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_tv43tvm', 'template_ukam2mp', form.current, 'J_RZqGwfU2pfxmsaE') 
       .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return (
        <div className="m-4 p-4 lg:m-5 lg:items-center">
           <div className="relative font-quicksand">
            <h2 className="text-2xl lg:text-3xl lg:mb-4 lg:font-bold">Ready to Go Further?</h2>
            <p className="text-sm text-justify lg:text-md lg:pb-3 lg:text-justify">Taking your goals and dreams off the page and implementing them in your life is hard work. Don’t go after it alone.  “Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort." —P. J. MEYER</p>
            </div>
           <div>
            <form ref={form} id="contact" onSubmit={sendEmail}>
            <input name="user_name" placeholder="Name" type="text" tabindex="1" required autofocus />
            <input name="user_email" placeholder="Email" type="email" tabindex="2" required />
            <input name="user_phone" placeholder="Phone" type="tel" tabindex="3" required />
            <textarea name="message" placeholder="I'm interested in" tabindex="5" required></textarea>
            <button type="submit" value="Send">Send</button>
            </form>
            </div>
        </div>
    ); 
}