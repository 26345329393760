import { Link } from "react-router-dom"
import Logo from "../images/lABL_logo.png"
import { useState, useRef, useEffect } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Navbar() {
    const [isDropdownOneVisible, setIsDropdownOneVisible] = useState(false);
    const [isDropdownTwoVisible, setIsDropdownTwoVisible] = useState(false);
    
    const toggleDropdown = () => {
      setIsDropdownOneVisible(!isDropdownOneVisible);
    };
    const toggleDropdownToo = () => {
        setIsDropdownTwoVisible(!isDropdownTwoVisible);
      };
      const dropdownRef = useRef();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
    return (
        <nav className="text-xs relative px-4 py-4 flex justify-between items-center bg-dk-blue text-white font-quicksand p-6 lg:sticky z-50 top-0">
            <img src={Logo} className="mr-2 h-28" alt="Logo"  /> 
            
                <div className="lg:text-2xl">
                        <div className="flex gap-6" >
                            <div className="mt-4 text-white"><Link to="/">Home</Link></div>
                            <div className="mt-4 text-white"><Link to="/AboutUs">About Us</Link></div>
                            <div className="mt-4 text-white"><Link to="/Services">Services</Link><button onClick={toggleDropdown}> <KeyboardArrowDownIcon/> </button> 
                                {isDropdownOneVisible && (
                                <ul ref={dropdownRef} hidden className="block bg-white rounded text-dk-blue text-lg m-2 p-3">
                                   <li onClick={toggleDropdown}><Link to="/Speaking">Speaking</Link></li> 
                                   <li onClick={toggleDropdown}><Link to="/Trainings">Training</Link></li>
                                    <li onClick={toggleDropdown}><Link to="/Coaching">Coaching</Link></li>
                                    </ul>
                                    )}
                                </div>
                                <div className="mt-4 text-white"><button onClick={toggleDropdownToo}>Health & Wellness <KeyboardArrowDownIcon/> </button> 
                                {isDropdownTwoVisible && (
                                <ul hidden className="block bg-white rounded text-dk-blue text-lg m-2 p-3">
                                   <li onClick={toggleDropdownToo}><Link to="/">Supplements</Link></li> 
                                   <li onClick={toggleDropdownToo}><Link to="/">Merch</Link></li>
                                    </ul>
                                    )}
                                </div>
                            <div className="mt-4 text-white"><Link to="/Contact">Contact Us</Link></div>
                            {/* <div className="mt-4 text-white"><Link to="/LoginPage">Log In</Link></div>    */}
                        </div>          
                </div>
        </nav>
       
    )
}