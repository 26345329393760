import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Components
import Navbar from "./Components/NavBar.jsx"
import Footer from "./Components/Footer.js"
import Home from "./Pages/Home.js"
import Contact from "./Components/Contact.js"
import AboutUs from "./Pages/AboutUs.js";
import Trainings from "./Pages/Trainings.js"
import Donate from "./Pages/Donate.js";
import { LoginPage } from "./Pages/LoginPage.js";
import Branding from "./Components/Branding/Branding.js";
import Coaching from "./Pages/Coaching.js";
import Speaking from "./Components/Speaking/Speaking.jsx";
import ServicesListing from "./Pages/ServicesListing.js";
import Register15Laws from "./Components/Training/Register15Laws.js";
export default function App() {
    return (
      <div>
       
        <Router>
          <Navbar />
               <Routes>  
                <Route path="/" element={<Home />} />
                <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/Services" element={<ServicesListing />} />
                <Route path="/Trainings" element={<Trainings />} />
                <Route path="/Speaking" element={<Speaking />} />
                <Route path="/Coaching" element={<Coaching />} />
                <Route path="/Branding" element={<Branding />} />
                <Route path="/Contact" element={<Contact />} /> 
                <Route path="/LoginPage" element={<LoginPage />} />
                <Route path="/Donate" element={<Donate />} />            
                <Route path="/Register" element={<Register15Laws />} />
            </Routes>
            <Footer />                
         </Router>
         
      </div> 
    )
}


