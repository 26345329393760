import React from 'react'
import YoutubeEmbed from './YoutubeEmbed'

export default function YTCard({embedId, ytTitle, ytDescribe}) {
  return (
    <div className="flex justify-center">
         <div>
          <YoutubeEmbed embedId={embedId} />     
        </div>
    </div>
  )
}
