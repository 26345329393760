import AboutCoaches from "../Components/Coaching/AboutCoaches";
import OurJourney from "../Components/Coaching/OurJourney";
import CoachingPackages from "../Components/Coaching/CoachingPackages";

export default function AboutUs() {
    return (
       <div>
        <OurJourney />
        <section>
            <AboutCoaches />
        </section>
        <CoachingPackages />
       </div>
    )
}