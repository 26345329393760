import MainTraining from "../Components/Training/MainTraining.js"
import VariousTrainings from "../Components/Training/VariousTrainings.js";
import MMGTraining from "../Components/Training/MMGTraining.js";
import Certifications from "../Components/Certifications.js";
export default function Trainings(){
    return (
        <div>
            <MainTraining />
            <VariousTrainings />
            <MMGTraining />
            <Certifications />
            
            
            
            </div>
      
    );
}

