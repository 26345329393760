import Services from "./Services"

export default function ServiceTitle() {
    return (
        <div className="bg-dk-blue">
             <Services />
            
        </div>
        
    )
}