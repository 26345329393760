import Coaches from "./Coaches.js";

export default function AboutCoaches() {
    return (
        <div className="bg-dk-blue">
            <h4 className="font-greatvibes text-white text-center m-2 p-5 lg:text-5xl">About The Coaches</h4>
            <Coaches />
            
            
        </div>
      
    )
}