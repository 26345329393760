import coaches from "../../data/coachesData.js"   
export default function Coaches() {
    return (
      <div className="m-2 p-2 lg:grid lg:grid-cols-2 lg:gap-5 lg:m-3">
        {coaches.map((coach, i) => (    
            <div className="text-center shadow-md shadow-pink p-10 rounded-xl my-6 font-quicksand text-dk-blue bg-white">
              <img key={i} className="lg:rounded-md lg:overflow-hidden" width="450px" height="350px" src={coach.img}  alt="" />
              <h2 className="text-2xl lg:text-3xl lg:mb-4 lg:font-bold">{coach.name}</h2>
              <p className="text-sm text-justify lg:text-md lg:pb-3 lg:h-36 lg:text-justify">{coach.description}</p>
              <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'><a href={coach.link}>More Info Coming Soon</a></button>
            </div>
        ))}
      </div>   
    )     
}