import Main from "../Components/Main"
import YTCard from "../Components/YTCard"

export default function Home() {
    return (
       <div>
            <Main />
            <YTCard embedId="pf9QX5D_oYM"/>
           
       </div>
    )
}