export default function MMGTraining(){
    return (
        <div className="container p-5 font-quicksand text-md">
            <h1 className="font-greatvibes text-3xl text-dk-blue text-center mb-5">Mastermind Group Training (MMG)</h1>
            <h2 className="font-bold">Key Benefits</h2>
            <p>There is synergy of energy, commitment and excitement that participants bring to a mastermind group. My facilitated groups offer a combination of masterminding, peer brainstorming, education, accountability and support in a group setting to sharpen your business and personal skills. By bringing fresh ideas and a different perspective, my masterminds can help you achieve success. </p>
            <p>Trainings (aka Mastermind Group or MMG) are studies on specific topics for 4 to 8-week sessions that will afford you the opportunity to join forces and mastermind with a unique group of like-minded individuals who are focused on taking results in every area of their lives to a new level in our personal and professional development. </p>
            <p>Having the support and ideas from other focused and driven people allows us to see things differently and to get a new perspective on goals and action plans.</p>
            <br />
            <h2 className="font-bold">Other benefits:</h2>
            <ul className="list-disc ml-10">
                <li>Increase your own experience and confidence</li>
                <li>Sharpen your business and personal skills</li>
                <li>Add an instant and valuable support network</li>
                <li>Get honest feedback, advice and brainstorming</li>
                <li>Borrow on the experience and skills of the other members</li>
                <li>Study Leadership, Communications and Growth principles/practices in a group setting, create an action plan and have the group hold you accountable for fulfilling your plan and goals.</li>
                <li>Receive critical insights into yourself</li>
                <li>Optimistic peer support in maintaining a positive mental attitude</li>
                <li>A sense of shared endeavor – there are others out there!</li>

            </ul>
            
            
            
            </div>
      
    );
}