

export default function Main() {
    return (
     
        <div className=" md: flex lg:flex p-10 gap-5 m-4 items-center ">
            <div className="overflow-hidden rounded-full">
                <img src={require("../images/va_asst.png")} alt="VA Asst" /> 
            </div>
            <div className="">
                <h2 className="font-greatvibes md:text-3xl lg:text-5xl">Welcome!</h2>
                <p className="font-quicksand m-0 md:text-lg lg:text-xl">Our mission is in our name.  We help people (professionals, businesses, and individuals) Live a Better Life 24•7 with outstanding tools and resources to guarantee success.</p>
            </div>
        </div>
        
    )
}