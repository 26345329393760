import ServicesTitle from "../Components/ServicesTitle";

export default function ServicesListing() {
    return (
        <div>
            <div className="bg-white">
            <h1 className="font-greatvibes text-dk-blue text-center m-5 p-3 lg:text-5xl">How Can We Serve You? </h1>
            <p className="font-quicksand text-center text-dk-blue lg:text-lg">We tailor our services to meet individual and corporate needs.</p>
           
        </div>
             <ServicesTitle />
        </div>
    );
}