const coaches = [
    {
        img: require("../images/CoachElizabeth.png"),
        name: "Coach Elizabeth",
        description: "I decided to 'design a life I loved' pursuing my passion and purpose and now I want to help others do the same.",
        long_desc: "Update",
        link: "#"
    },
    {
        img: require("../images/CoachAL.png"),
        name: "Coach Alexander",
        description: "I transitioned from not only being a sport's coach, but also a Life Coach to help others pursue their mission and purpose.",
        long_desc: "Coach Bright, affectionally known by many, is certified with the John Maxwell Team as Speaker, Trainer, and Life Coach, as well as, a D.I.S.C. consultant.  He is trained on topics of personal and professional development, leadership and communication, and conflict management.Coach Bright enjoys teaching, mentoring, and coaching.  He is also the Lead Pastor of a church and an Ordained Bishop with the Church of God in Cleveland, Tennessee.  He also graduated from P&B Cosmetology Technical School and furthered his education at Rowan University with a Bachelor of Arts in Mathematics and a concentration in Secondary Education.  After graduating from Rowan University, Coach Bright started his teaching career as a Mathematics teacher, as well as, a coach of multiple sports.",
        link: "#"
    }
];

export default coaches;