import AboutCoaches from "./AboutCoaches";


export default function CoachingPackages() {
    return (

      <div className='bg-dk-blue rounded-lg text-white'>
         <h1 className="font-greatvibes text-3xl text-center p-5">The Levels of Coaching Support</h1>
        <div className="flex-2">
        <div className='mx-auto font-quicksand'>
          <h1 className='text-lg text-center'>Individual Coaching</h1>
            <div className="m-4 p-4 lg:gap-10 lg:m-5">
              <p className='text-md'>When I engage in personalized coaching sessions with you, my utmost aim is to assist you in realizing your purpose, vision, and objectives by tailoring John Maxwell's programs to your unique requirements. Collaboratively, we'll devise a potent, strategic roadmap for you, fostering clarity through consistent weekly sessions that encompass interaction and mutual accountability. How are you prioritizing self-investment? How are you extending that investment to others?</p>
              <a href="/Contact" className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-dk-blue bg-white rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Sign Up</a>
            </div> 
            </div>
          <div className='mx-auto font-quicksand'>
          <h1 className='text-lg text-center'>Group Coaching</h1>
            <div className="m-4 p-4 lg:gap-10 lg:m-5">
              <p className='text-md'>When I engage with a group – while it's not individual-focused – harnessing the collective insights amplifies the depth of discussions and yields solutions surpassing individual capabilities. This cohesive unity fosters personal leadership growth and enables each member to flourish in a supportive environment that nurtures the entire group. It's an unparalleled experience akin to an academic think tank, fostering innovation and collaboration. </p>
              <a href="/Contact" className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-dk-blue bg-white rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Sign Up</a>
           
            </div> 
        </div>
        </div>
       
      
    </div>    
      
    )
}