export default function Certifications(){
    return (
        <div>
            <img />
            <img />
            <img />
            
            </div>
      
    );
}